@import "~bootstrap/scss/bootstrap";
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #5CA699;
    border-color: #5CA699;
    border-radius: 25px!important;
    // margin-right: 4px;
}
.page-link {
    // padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #1a202c;;
    background-color: #dee2e6;
    border-radius: 25px;
    z-index: 0;
}
.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.flex-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .flex-container > div {
  padding-top:10px;
  }
  .paginate-ctn{
      display: flex;
  }
  .round-effect{
    color:#DD4124;
    cursor:pointer;
  /*   border:1px solid red; */
    
    font-size:16px;
    text-align:center;
    padding-left:10px;
    padding-right:10px;
    padding-top:9px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin:5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
     text-decoration: none;
  }
  
  .round-effect:hover{
    text-decoration: none;
    background:grey;
    color:#FFF;
  }
  .round-effect:hover a {
    text-decoration: none;
    color:#FFF;
  }
  