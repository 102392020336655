.product-sec2 {
  padding-left: 40px;
  /* border: 1px solid red; */
}

.my-row {
  margin-right: -20px;
}

.my-col {
  padding: 0 7px;
  margin-bottom: 15px;
}

.product-title {
  padding-left: 7px;
}

.product-img {
  border: 1px solid #f3f2f2;
}

.main-card {
  border-radius: 0;
}

.card-body {
  text-align: center;
  padding: 20px 5px;
  min-height: 153px;
}

.my-card-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 3px;
  text-align: center;
}

.my-card-price {
  font-size: 15px;
  font-weight: 600;
  color: #2d57a6;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 8px;
}

.mrp-price {
  margin-right: 8px;
  color: #8a8a8a;
}

.card-btn {
  /* width: 65%; */
  padding: auto 0 !important;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 4px;
  background: var(--my-yellow) !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  padding: 8px 15px !important;
  letter-spacing: 1px;
  transition: 0.3s ease-in;
}

.card-btn:hover {
  background: #333333 !important;
}

.added-btn{
  background: rgba(36, 107, 94, 0.8) !important;
  color: #fff;
}

.category-sec-inner:last-child .category-text {
  border-bottom: none;
}

.product-pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: 5px;
  margin-left: 5px;
  background: #ebebeb;
  margin-top: 20px;
  padding: 10px 20px;
}

.product-quantity h5 {
  color: #727272;
  font-size: 13px;
  font-weight: 500;
}

.page-item .page-link {
  margin-left: 3px;
  font-size: 12px;
  font-weight: 500;
  background: #c3c3c3;
  padding: 5px 9px;
  border-radius: 50%;
}

.page-item .page-link {
  box-shadow: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5ca699;
  border-radius: 50% !important;
  padding: 7px 12px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

@media (max-width: 1120px) {
  .my-row {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .my-row {
    margin-right: 0;
  }
  .product-sec2 {
    padding-left: 0px;
  }
  .product-title {
    text-align: center;
  }
}

@media (max-width: 489px) {
  .product-pagination {
    display: block;
    text-align: center;
    padding: 13px 20px;
  }
  .pagination {
    justify-content: center;
  }
  .product-quantity h5 {
    text-align: center;
    margin-bottom: 5px;
  }
}
