@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

:root {
  --my-yellow: #bd9847;
  --my-yellow2: #bd9846;
  --my-green: #78B3A8;
  --my-green2: #3a9286;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-app,
.text-muted {
  color: #bd9849 !important;
}

.bg-app {
  background-color: #fff;
}

.bg-app-secondary {
  background-color: #333333;
}
.breadcrumb {
  background-color: "white";
  border: "1px solid rgba(0, 0, 0, 0.125)";
  border-radius: "0.37rem";
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem;
  /* background-color: #EBEBEB !important; */
}

.ContactSection_container__2V_LO {
  display: none !important;
}

h3 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #fff;
  letter-spacing: 4px;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 30px !important;
  text-transform: uppercase;
}

/* h3::before{
  content: "";
  width: 80px;
  background: #fff;
  height: 4px;
  position: absolute;
  bottom: 0;
} */

h3::before {
  content: "";
  width: 80px;
  background: #fff;
  height: 4px;
  position: absolute;
  bottom: 0;
}

:focus {
  outline: none !important;
}

.navbar button:hover {
  background: #fff;
  border: 1px solid #c29d4b;
}

.form-sec{
  width: 70%;
  margin: 0 auto;
}

.form-sec h3{
  font-weight: 600 !important;
}

.form-sec h3::before{
   background: #38907e;
}

.form-sec a{
 color: #007bff;
}

.form-sec input{
  padding: 15px 10px;
  font-size: 15px !important;
}

 .signup-sec{
   text-align: center;
   margin-top: 20px;
}

 .signup-sec p{
   font-size: 14px;
   margin-right: 15px !important;
}

.forget-sec{
  text-align: center;
  font-size: 16px !important;
  margin-top: 20px;
}

.my-btn{
  background: #38907e;
  color: #fff;
  font-size: 14px;
  padding: 10px 35px;
  border-radius: 4px;
  transition: 0.3s ease-in;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
}

.my-btn:hover{
  background: #212529;
  color: #fff;
}

.signup-sec-main h3::before{
  left: calc(50% - 45px);
}

.forget-sec-main h3::before{
  left: calc(50% - 45px);
}

.otp-sec h3::before{
  left: calc(50% - 45px);
}

.otp-sec span{
 color: #0e6dd3;
 font-weight: 600;
}


.otp-input{
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 15px !important;
}

.otp-sec{
  /* border: 1px solid red; */
  width: 100%;
}

.resend-block {
  text-align: center;
  margin-top: 20px;
}

.resend-block button{
text-decoration: underline;
color: #0e6dd3;
}
.resend-block button:hover{
background: none;
text-decoration: none;
}

.add-remove-btn{
  color: #000;
  font-weight: 500;
  font-size: 22px;
}

.payment-sec{
  margin-top: 20px !important;
}

.back-btn {
  border-radius: 6px;
  padding: 10px 0px;
  border: 1px solid #ccc !important;
}
.razorpay-button-container {
  text-align: center;
  width: 100%;
  border: 1px solid #eee;
  margin-bottom: 15px;
  background: #eee;
  border-radius: 4px;
  min-height: 55px;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.razorpay-button-container:hover {
  opacity: 0.8;
}

.razorpay-button-container img {
  margin: auto;
  width: 140px;
}

.orImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.check-btn {
  margin-top: 20px !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-btn .my-btn{
  background: #38907e !important;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 4px;
  transition: 0.3s ease-in;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  font-weight: normal;
}

.my-btn:hover{
  background: #212529 !important;
  color: #fff;
}

.manufacturer{
  font-size: 15px ;
}
.manufacturer span{
  font-weight: 600 ;
}

.price-block{
  font-size: 20px;
  font-weight: 600;
}

.react-multiple-carousel__arrow {
  min-width: 33px !important;
  min-height: 33px !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}
.react-multiple-carousel__arrow--right {
  right: 0 !important;
}
.react-multiple-carousel__arrow::before {
  font-size: 15px !important;
}

.pro-detail-sec .react-multi-carousel-item {
  margin: 0 2px;
  width: 75px!important;
}

.notification__message {
  color: #fff;
}

.sticky-whatsapp {
  position: fixed;
  bottom: 25px;
  right: 45px;
  z-index: 1000;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transition: all 0.3 ease-in-out;
}

.sticky-whatsapp:hover {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.3 ease-in-out;
}

@media (max-width: 500px) {
  .react-multi-carousel-list  {
    max-width: 200px;
    margin: auto;
  }
  .sticky-whatsapp {
    bottom: 15px;
    right: 25px;
  }
  .sticky-whatsapp img{
    width: 40px;
  }
}