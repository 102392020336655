.not-found {
    text-align: center;
}

.not-found h2 {
    font-size: 28px;
    font-weight: bold;
}

.oops-img img {
    margin: 0 auto;
    width: 400px;
    margin-bottom: 30px;
}