.offer-sec {
    padding: 50px 0 30px;
}

.offer-sec .offer-img{
    width: 100%;
}

@media (max-width: 991px) {
    .offer-sec .left-col {
        margin-bottom: 20px;
    }
}


@media (max-width: 479px) {
    .offer-sec {
        padding: 30px 0 0;
    }
}