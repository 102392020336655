.appContainer {
  padding-left: 16rem;
  padding-right: 16rem;
}

.appColor {
  color: #bd9848;
}

@media screen and (max-width: 576px) {
  .appContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .appContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .appContainer {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
