.socialItems{
	display: flex;
	width: 100%;
	justify-content: center;
	background: #000;
	color: #fff;
	margin-bottom: 5px;
}
.socialItems a{
	font-weight: 300;
}

.socialItem{
	font-size: 12px;
	padding: 6px 18px;
}


@media (max-width: 991px){
	.socialItems{
		width: 100%;
		text-align: center;
		padding: 6px 20px;
	}
}

@media(max-width: 767px){
	.socialItem{
		font-size: 12px;
		padding: 4px 0px;
	}
}

@media(max-width: 479px){
	.socialItem {
    padding: 4px 0px;
		font-size: 11px;
}
.socialItems{
	padding: 6px 10px;
}
}
