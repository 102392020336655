// .container {
//     padding-left: 16rem;
//     padding-right: 16rem !important;
//     background-color: #191919;
//     color: white;
// }
// @media screen and (max-width: 576px) {
//     .container {
//         padding-left: 1rem;
//         padding-right: 1rem;
//     }
// }
// @media screen and (min-width: 576px) and (max-width: 992px) {
//     .container {
//         padding-left: 2rem;
//         padding-right: 2rem;
//     }
// }
// @media screen and (min-width: 992px) and (max-width: 1440px) {
//     .container {
//         padding-left: 4rem;
//         padding-right: 4rem;
//     }
// }
.list {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    //background-color: #2196F3;
    padding: 10px 0;
}

.list1 {
    letter-spacing: 5px;
    font-size: 16px;
    margin-bottom: 25px !important;
    font-weight: 500;
}

.list2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}



