.container {
  padding-left: 16rem;
  padding-right: 16rem;
  margin-bottom: 0;
  background-image: url("/images/hero_bg.png");
  background-repeat: no-repeat;
  :global(.col-12),
  :global(.col-md-6) {
    padding: 0;
  }
}

.bannerImg {
  text-align: center;
  margin: auto;
  min-height: 150px;
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 1rem;
  }
}

.firstBigText,
.secondBigText {
  font-weight: bolder;
  font-size: 3rem;
  line-height: 3rem;
}

.firstBigText {
  color: black;
}

.secondBigText {
  color: #bd9848;
}

.containerPara {
  color: black;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
