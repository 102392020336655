
.aboutPage-heading{
  text-align: center;
  width: 100%;
}
.aboutPage-heading h3{
  color: #000;
  text-align: center;
  font-size: 22px !important;
  margin-top: 20px;
}
.aboutPage-heading h3::before{
  background: var(--my-yellow);
  left: calc(50% - 45px);
}
