@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* font-family: 'Montserrat', sans-serif; */

h4 {
    font-size: 13px;
    font-weight: 700;
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    transition: 0.3s ease-in;
}

.searchContent {
    max-width: 260px;
    border: 1px solid rgb(255, 255, 255, 0.9);
    overflow-x: hidden;
}

h4:hover {
    color: #78B3A8 !important
}

.menus {
    margin-bottom: 10px;
}

.menus .navbar {
    padding: 0px;
}

.menus .navbarItem {
    padding: 5px;
}

.navbar .navbar-toggler {
    font-size: 16px;
    margin-right: 20px;
    padding: 4px 10px;
}

.basic-navbar-nav {
    margin-left: 50px;
}

.menus .navbarItem .nav-link {
    font-weight: bolder !important;
}

.menus .navbarItem .nav-link:last-child {
    margin-right: 0 !important;
}

.menus ::placeholder {
    font-weight: 600;
}

.mobileForm {
    display: none;
}

.mobileSearchContent {
    display: none;
}

.searchContent {
    position: absolute;
    z-index: 100;
    background: #fff;
    width: 100%;
    top: 43px;
}

.searchContent .item-name,
.mobileSearchContent .item-name {
    font-size: 13px;
    font-weight: 500;
    padding-right: 5px;
}

@media(max-width: 991px) {
    .mobileForm {
        display: flex;
        width: 200px;
        position: relative;
    }
    .mobileSearchContent {
        display: block;
        position: absolute;
        z-index: 999;
        background: #fff;
        right: 5px;
        top: 47px;
        width: 200px;
    }
    .searchContent {
        display: none;
    }
    .form {
        display: none !important;
    }
    .menus .navbar {
        padding: 10px 5px;
    }
}

@media(max-width:768px) {
    .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }
    .searchicon {
        right: 40px !important;
        top: 6px;
    }
    .navbarItem .nav-link {
        padding: 10px 0;
        margin: 0;
    }
}