.cartBtns {
    border-color: #bd9848;
    border-radius: 15px !important;
    height: 30px;
    width: 30px;
    &:active {
        color: #fff !important;
        background-color: #333333 !important;
        border-color: #333333 !important;
    }
    &:hover {
        color: #fff;
        background-color: #333333;
        border-color: #333333;
    }
    &:focus {
        color: #fff !important;
        background-color: #333333 !important;
        border-color: #333333 !important;
        box-shadow: none !important;
    }
}

.updateQtySec {
    display: flex;
}

.detailCard {
    margin-left: 25px;
}

.updateQtySec span {
    margin-right: 13px;
    font-weight: bold;
}

.mrpPriceBlock span {
    font-weight: bold;
}

.productAttrKey {
    padding-right: 8px;
}

.attrValue {
    padding-left: 5px;
}

.highlightList {
    padding-left: 20px;
}

.highlightItem {
    list-style-type: circle;
}

.thumbs img {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #dddd;
}

.thumbs li {
    margin-left: 8px;
}

.thumbs img:hover {
    border: 1px solid rgba(255, 159, 159, 0.867);
}

@media(max-width: 620px) {
    .leftContainer {
        width: 60%;
        padding-right: 15px;
    }
    .leftContainer .productName {
        font-size: 22px !important;
    }
    .rightContainer {
        width: 40%;
    }
    .detailCard {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media(max-width: 500px) {
    .mainContainer {
        flex-direction: column-reverse;
    }
    .priceBlock {
        text-align: left;
    }
    .leftContainer {
        width: 100%;
        padding-right: 15px;
        text-align: center;
    }
    .rightContainer {
        width: 100%;
    }
}