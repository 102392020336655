@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.container {
  max-width: 1044px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #696969;
  font-family: "Poppins", sans-serif;
}

.loginBlock {
  display: flex;
  align-self: center;
  margin-left: 15px;
  align-items: center;
}

.loginBlock a {
  font-size: 13px;
  font-weight: 500;
}

h6 {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 4px;
}

.userSec {
  display: flex;
  align-items: center;
}

.currencyBlock {
  align-self: center;
  border-right: 1px solid #696969;
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
}

.cartBlock {
  align-self: center;
  border-right: 1px solid #696969;
  position: relative;
  padding-right: 15px;
}

.cartBlock:hover,
.loginBlock:hover {
  color: #78b3a8;
  transition: 0.3s ease-in;
}

.cartBlock span {
  background: #696969;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px !important;
  font-weight: 500;
  position: absolute;
  text-align: center;
  top: -12px;
  left: 10px;
}

.userBlock {
  display: flex;
  padding-left: 22px;
}

.userBlock:hover {
  color: #78b3a8;
  transition: 0.3s ease-in;
}

.svg-inline--fa.fa-w-16 {
  width: 12px;
}

.icons {
  font-size: 13px;
}

.inputGroup {
  :global(#pincode-dropdown),
  :global(input) {
    overflow: hidden;
    color: black;
    background-color: #f4f4f4;
    border: none;
    text-overflow: ellipsis;
  }
  :global(#pincode-dropdown:focus),
  :global(input:focus) {
    box-shadow: none;
    background-color: #f4f4f4;
  }
}

.searchBtn {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bd9848;
  color: white;
  border: none;
}

.searchBtn:hover,
.searchBtn:active,
.searchBtn:focus {
  background: #bd9848;
  box-shadow: none;
}

.searchBar {
  height: 2.5rem;
  font-size: 14px;
}

.pincodeDropdown {
  button {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

.productItem {
  &:hover {
    background-color: #f8f9fa;
  }
}

@media (max-width: 991px) {
  // .loginBlock {
  //   min-width: 154px;
  // }
  .block {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  // .loginBlock {
  //   min-width: 100px;
  //   padding: 10px;
  //   display: none;
  // }
  .block {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 5px;
  }

  .userSec {
    display: flex;
    align-items: center;
    margin: 15px 0;
  }
  .companyLogo img {
    width: 160px !important;
  }
}

@media (max-width: 550px) {
  .block {
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .companyLogo img {
    width: 130px !important;
  }

  .cartBlock {
    padding-right: 14px;
  }
  .userBlock {
    display: flex;
    padding-left: 14px;
  }
  .block{
    padding: 0;
  }
  h6{
    font-size: 12px;
  }
}
