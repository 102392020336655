#paymentGatewayListContainer {
    padding: 20px 5px 20px 0;
}

#paymentGatewayListContainer input[type=radio] {
    height: 20px;
    width: 20px;
}

.paymentGatewayListTitle {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.paymentGatewayRow {
    padding: 17px 10px;
    border-bottom: 1px solid #ccc;
}

.paymentGatewayRow label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.paymentLogo {
    height: 45px;
    margin-left: 12px;
}

.paymentOptionName {
    margin-left: 20px;
    font-weight: 600;
}

.loading {
    padding: 25px;
}

.payNowButton,
.loadingButton {
    min-width: 150px;
    width: 100%;
    padding: 10px 15px;
    min-height: 45px;
    cursor: pointer;
    color: #fff;
    background-color: #000;
    font-weight: 500;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.payNowButton:hover,
.loadingButton:hover {
    border: 1px solid rgb(86, 86, 86);
    background-color: #000;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.payNowButtonContainer {
    display: block;
}

.errorMsg {
    color: red;
    padding: 15px;
}