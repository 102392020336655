
.my-breadcrumb{
  align-items: center;
  background: #ebebeb;

}

.content{
  display: flex;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 0;
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: -5px;
  flex-wrap: wrap;
}

.content a{
  text-decoration: none;
  padding: 0 5px;
  text-transform: capitalize;
}

.content .active {
  color: rgb(119, 118, 118);
  cursor: auto;
}

