.myContainer {
    max-width: 1044px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}

.navbarItem {
    justify-content: space-between;
    padding: 0;
    font-size: 13px !important;
}
.navbarItem .nav-link {
   border: 1px solid red;
}

.navbarItem a {
    margin-right: 20px !important;
    color: #000 !important;
    transition: 0.3s ease-in;
}

.inputGroup input{
    border-radius: 5px !important;
    font-size: 13px;
    width: 260px !important;
}

.navbar {
    padding: 0 !important;
}

.searchicon{
    color: #696969;
}
::placeholder{
   color: red;
}

.searchicon {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 10;
    /*this will keep the icon appearing all time, even when on input::focus*/
}



@media(max-width: 991px) {
    .navbarItem a {
        margin: 5px 0;
    }
}

@media(max-width: 768px) {
    .searchicon {
        position: absolute;
        right: 15px;
        top: 6px;
        z-index: 10;
        /*this will keep the icon appearing all time, even when on input::focus*/
    }
}




