.subscription-sec {
    background: url(../../../images/newsletter-bg.jpg) no-repeat center center;
    width: 100%;
    text-align: center;
}

.input-block {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    position: relative;
}

.form-input {
    width: 440px;
    margin-right: 10px;
    padding: 13px;
    font-size: 14px;
}

::placeholder {
    font-size: 12px;
}

h5 {
    color: #fff;
    font-size: 14px;
}

.subscription-sec h3::before {
    left: calc(50% - 45px);
}

.msg-block {
    position: absolute;
    bottom: -34px;
    left: calc(50% - 262px);
}

.input-block .my-btn{
  background: #fff;
  color: #3066c9;
  font-size: 14px;
  padding: 5px 35px;
  border-radius: 4px;
  transition: 0.3s ease-in;
  font-weight: 600;
  letter-spacing: 1px;
}
.input-block .my-btn:hover{
  background: #212529;
  color: #fff;
}

@media(max-width: 767px) {
    .form-input {
        width: 305px;
    }
    .input-block {
        margin-top: 30px;
    }
    h3{
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .subscription-sec .block {
        padding: 50px 0;
    }
}
/* 767px  */


@media(max-width: 479px) {
    .form-input {
        padding: 9px 15px;
        display: block;
        width: 100%;
    }
    .input-block .my-btn {
        padding: 0px 17px;
        font-size: 13px;
    }
}
/* 479px  */


