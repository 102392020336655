.textblock1 {
    position: absolute;
    bottom: 100px;
    right: 450px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-newsletter {
    padding: 80px 0;
    margin-bottom: -27px;
    /* background: #f84e77; */
    }
    
    .home-newsletter .single {
    max-width: 650px;
    margin: 0 -280px;
    text-align: center;
    position: relative;
    z-index: 2; }
    .home-newsletter .single h2 {
    font-size: 22px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 40px; }
    .home-newsletter .single .form-control {
    height: 50px;
    width: 550px;
    /* background: rgba(255, 255, 255, 0.6);
    border-color: transparent; */
    border-radius: 7px 7px 7px 7px; }
    .home-newsletter .single .form-control:focus {
    box-shadow: none;
    border-color: #243c4f; }
    .home-newsletter .single .btn {
    min-height: 50px; 
    border-radius: 7px 7px 7px 7px;
    background: #fff;
    color: #0760a7;
    }