
.similar-product-sec {
  margin-bottom: 40px;
}

.similar-product-sec h3 {
 color: #000;
 text-align: center;
}

.similar-product-sec h3::before {
  background: rgb(56, 147, 130, 0.8);
  left: calc(50% - 48px);
}

.similar-product-sec .react-multi-carousel-list {
  max-width: inherit;
  margin: auto;
}