.simpleBtn {
	color: #fff;
	background-color: #5CA699;
	border-color:#5CA699;
	font-size: 14px;
	&:hover {
		color: #fff;
		background-color: #333333;
		border-color: #333333;
	}
	&:active {
		color: #fff;
		background-color: #333333;
		border-color: #333333;
    }
    &:focus {
		box-shadow: none;
	}
}
.secondaryBtn {
	color: #000;
	background-color: #fff;
	border-color: #fff;
	&:hover {
		color:white;
		background-color: #333333;
		border-color: #333333;
	}
	&:active {
		color: #000;
		background-color: #fff;
		border-color: #fff;
    }
    &:focus {
		box-shadow: none;
	}
}
