footer {
    background: #191919;
    color: #fff;
    padding: 65px 0;
    font-size: 12px !important;
}

.myContainer {
    max-width: 1044px;
    width: 100%;
    margin: 0 auto;
    padding: 0 5px;
}

footer .myContainer {
    padding: 0 15px;
 }

.row {
    margin-right: 0px;
    margin-left: 0px;
}

.addressBlock {
    display: flex;
    max-width: 200px;
}

.mapMarker {
    display: block;
    margin-top: 3px;
}



.quick-links-col,
.footer-contact-col,
.footer-contact2-col {
    text-align: left;
}

.quick-links {
    padding-top: 0 !important;
}

.footer-contact2-col {
    align-self: self-end;
}

.footer-contact-col .envelopeBlock {
    margin: 0px !important;
}

.quick-links {
    margin: 0 !important;
}



@media(max-width: 991px) {
    footer {
        padding: 45px 0 55px;
    }
    footer .row {
        justify-content: center;
        text-align: center;
    }
    .quick-links {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        padding: 10px 0;
        margin: 0 auto;
    }
    .addressBlock {
        display: flex;
        max-width: 100%;
        justify-content: center;
        margin: 0;
    }
    .footer-title {
        letter-spacing: 5px;
        font-size: 16px;
        font-weight: 500;
    }
    .quick-links  {
        margin-bottom: 15px;
    }
    .envelopeBlock {
        margin: -10px;
    }
}

@media(max-width: 767px) {
    footer {
        padding: 50px 0 50px;
    }
    .quick-links {
        max-width: 350px;
    }
    .addressBlock{
        justify-content: start;
    }
    .footer-title {
        margin-bottom: 20px !important;
    }
    .quick-links{
        margin-bottom: 20px !important;
    }
    .address-title{
        display: none;
    }
}


@media(max-width: 478px){
    
}