.category-text {
    display: flex;
    padding: 12px 0;
    border-bottom: 1px solid rgb(221, 219, 219);
    cursor: pointer;
    transition: 0.3s ease-in;
    align-items: center;
}

.category-text:hover,
.activeCategoryText {
    font-weight: bold;
}
.activeCategoryText h4{
  font-weight: 600 !important;
  color: var(--my-green);
}

.category-text:hover h4 {
    color: var(--my-green);
}

.category-icon {
    margin-right: 8px;
    font-size: 12px;
    color: var(--my-yellow);
}



.category-text:hover .category-icon, 
.activeCategoryText .category-icon{
  color: var(--my-green);
}

.category-text h4 {
    font-size: 17px;
    font-weight: 500;
    font-family: 'Source Sans Pro', sans-serif;
}

.products-sec h3 {
    color: #000;
    font-weight: 600 !important;
}

.products-sec h3::before {
    background: rgb(56, 147, 130, 0.8);
}

.categories-sec{
    text-align: center;
    padding: 60px 0;
}
.categories-sec h3 {
    color: #000;
    font-weight: 600 !important;
}

.categories-sec h3::before {
    background: rgb(56, 147, 130, 0.8);
    left: calc(50% - 48px);
}

.categories-sec .card-body {
    text-align: center;
    padding: 20px 5px;
    min-height: 0px;
}

.amount-sec {
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
}

.amount-sec h6 {
    color: #454545;
    font-weight: 600;
    font-size: 14px;
    margin-left: 0;
}

.slide-container {
    padding-right: 10px;
}

.slide-container input {
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    background: #3a9286;
    height: 5px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #454545;
    cursor: pointer;
}

.filter-sec{
    display: none;
}

.product_detail{
 padding-left: 40px;
}

.product_detail .prod_left {
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0;
  }
  
  .product_detail #sync1 {
    border: 1px solid #dddddd;
    border-radius: 5px;
  }
  
  .product_detail #sync1 figure img {
    border-radius: 5px;
  }
  
  .product_detail #sync2 figure {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #dddddd;
    border-radius: 5px;
  }
  .product_detail #sync2 figure img {
    border-radius: 5px;
  }
  .product_detail #sync2 .owl-item {
    opacity: 0.5;
  }
  .product_detail #sync2 .owl-item.current {
    opacity: 1;
  }
  
  .product_detail .thum_slider {
    width: 100%;
    float: left;
    margin: 20px 0 0 0;
  }
  
  .product_detail .owl-carousel .owl-nav button.owl-prev,
  .product_detail .owl-carousel .owl-nav button.owl-next {
    width: auto;
    height: auto;
    font-size: 0;
    display: block;
    border-radius: 0;
    position: absolute;
    top: 50%;
    margin: 0;
    transform: translate(0, -50%);
    background: none !important;
    z-index: 555;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    color: #2f2583 !important;
  }
  .product_detail .owl-carousel .owl-nav button.owl-prev {
    left: -30px;
  }
  .product_detail .owl-carousel .owl-nav button.owl-prev:after {
    content: "\f104";
    font-family: "FontAwesome";
    font-size: 30px;
  }
  .product_detail .owl-carousel .owl-nav button.owl-next {
    right: -30px;
  }
  .product_detail .owl-carousel .owl-nav button.owl-next:after {
    content: "\f105";
    font-family: "FontAwesome";
    font-size: 30px;
  }
  .product_detail .owl-carousel .owl-nav button.owl-prev:hover,
  .product_detail .owl-carousel .owl-nav button.owl-next:hover {
    background: none !important;
    color: #59b02f !important;
  }
  
  /*.product_detail #sync2 .owl-item.current.active figure{ border-color:#0071ce;}*/
  
  .prod_info_right {
    width: 100%;
    float: left;
    margin: 0 0 0 0;
    padding: 0;
  }
  .prod_info_right h2 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 26px;
    color: #3a3a3a;
  }
  .prod_info_right .rating {
    padding: 0;
    margin: 0 0 11px 0;
    font-size: 16px;
    color: #fdb400;
  }
  .prod_info_right .rating i {
    margin: 0 2px;
  }
  .prod_info_right .price {
    padding: 0;
    margin: 0 0 32px 0;
    font-size: 26px;
    color: #f72b60;
  }
  .prod_info_right h5 {
    padding: 0;
    margin: 0 0 11px 0;
    font-size: 18px;
    color: #3a3a3a;
  }
  .prod_info_right p {
    padding: 0;
    margin: 0 0 31px 0;
    font-size: 16px;
    color: #717171;
    line-height: 25px;
  }
  .size_info {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .size_info .form-group {
    width: 100%;
    float: left;
    margin: 0 0 37px 0;
  }
  .size_info label {
    padding: 0;
    margin: 0 0 15px 0;
    font-size: 18px;
    color: #3a3a3a;
    display: block;
  }
  select.select_info {
    width: 100%;
    height: 46px;
    float: left;
    padding: 0 30px 0 18px;
    margin: 0;
    font-size: 16px;
    color: #717171;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* background: #f1f1ed url(images/select_arrow.png) no-repeat right 15px center; */
    border-radius: 3px;
  }
  
  .quantity_info {
    width: 100%;
    max-width: 130px;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
  }
  .quantity_info .btn-default {
    width: 38px;
    margin: 0;
    padding: 0;
    background: none;
    font-size: 30px;
    color: #393e42;
    font-weight: 400;
    border: none;
    box-shadow: none;
    height: 46px;
    line-height: 46px;
    background: #f1f1ed;
    border-radius: 3px 0 0 3px;
  }
  .quantity_info .btn-default.btn2 {
    border: none;
    border: none;
    border-radius: 0 3px 3px 0;
  }
  .quantity_info .form-control {
    height: 46px;
    background: #f2f5fa;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 16px;
    color: #717171;
    text-align: center;
    background: #f1f1ed;
  }
  .quantity_info .btn-default:hover {
    background: #f72b60;
    color: #fff;
  }
  
  .add_btn_info {
    margin-bottom: 45px;
  }
  .prod_info_right .btns {
    width: 100%;
    max-width: 205px;
    margin: 0 15px 15px 0;
    text-align: center;
  }
  
  .prod_share {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  ul.share_list {
    padding: 0;
    margin: 0;
  }
  ul.share_list li {
    padding: 0;
    margin: 0 7px 0 0;
    display: inline-block;
  }
  ul.share_list li a {
    width: 48px;
    height: 46px;
    padding: 0;
    margin: 0;
    display: block;
    background: #f1f1ed;
    border-radius: 3px;
    text-align: center;
    line-height: 46px;
  }
  ul.share_list li a:hover {
    background: #f72b60;
  }
  ul.share_list li a .fa-facebook {
    color: #5472b5;
  }
  ul.share_list li a .fa-twitter {
    color: #1b9ef1;
  }
  ul.share_list li a .fa-pinterest-p {
    color: #e40011;
  }
  ul.share_list li a .fa-instagram {
    color: #c4408b;
  }
  ul.share_list li a:hover i {
    color: #fff;
  }
  
  .prod_tab {
    width: 100%;
    float: left;
    padding: 0 0 30px 0;
    margin: 30px 0 0 0;
    border-bottom: 1px solid #dddddd;
  }
  .prod_tab p {
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 16px;
    color: #707070;
    line-height: 26px;
    font-family: "Roboto", sans-serif;
  }
  .prod_tab .nav-tabs {
    border-color: #dddddd;
  }
  .prod_tab .nav-tabs .nav-item {
    padding: 0 35px 0 0;
    margin: 0 14px 0 0;
    position: relative;
  }
  .prod_tab .nav-tabs .nav-item:after {
    content: "";
    width: 2px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 20px;
    background: #3a3a3a;
  }
  .prod_tab .nav-tabs .nav-item:last-child {
    padding-right: 0;
    margin-right: 0;
  }
  .prod_tab .nav-tabs .nav-item:last-child:after {
    display: none;
  }
  .prod_tab .nav-tabs .nav-link {
    padding: 11px 15px 1px 0px;
    font-size: 26px;
    color: #3a3a3a;
    border: none;
    border-radius: 0;
  }
  .prod_tab .nav-tabs .nav-link.active,
  .prod_tab .nav-tabs .nav-item.show .nav-link {
    color: #f72b60;
  }
  .prod_tab .tab-content > .tab-pane {
    padding: 35px 0 0 0;
  }