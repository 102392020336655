.heading {
  border-bottom-color: #bd9848;
}

.heading h3 {
  text-align: left;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem;
}
.addButton {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 25px !important;
}


@media (max-width: 991px) {
  .heading h3 {
    text-align: center;
  }
}