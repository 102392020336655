.imgBlock1 {
    margin-bottom: 23px;
}

.about-block {
    background: url(../../../images/about-us-bg.jpg) no-repeat center center;
    min-height: 478px;
}

.about-para .para2 {
    display: none;
}

.about-col-right {
    position: relative;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.offer-row {
    justify-content: center;
}

.offer-col {
    width: auto;
}

.block {
    padding: 70px 0;
}

.about-text {
    min-height: initial;
    padding: 40px 42px;
    position: absolute;
    top: 0;
}

.about-text h3 {
    padding-bottom: 20px;
    margin-bottom: 20px !important;
}

.about-text .btn-block a {
    padding: 10px 40px !important;
}

.about-text .btn-block {
    margin-top: 27px;
}

.about-text p {
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
    line-height: 1.7;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.about-para {
    margin-bottom: 20px;
}

.btn-block {
    margin-top: 50px;
}

.btn-block a {
    background: #fff;
    color: #317c6d !important;
    padding: 14px 53px !important;
    border-radius: 4px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    transition: 0.2s ease-in;
    display: inline-block;
}

.btn-block a:hover {
    background: #212529;
    color: #fff !important;
}

.product-sec {
    padding: 78px 0;
}

.product-sec h3 {
    text-align: center;
    color: #000;
    font-weight: 600 !important;
}

.product-sec h3::before {
    background: #389382;
    left: calc(50% - 45px);
}

.product-box {
    position: relative;
    margin-bottom: 30px;
}

.product-box img {
    width: 100%;
    position: relative;
}

.productBtn-block {
    position: absolute;
    z-index: 1000;
    bottom: 0;
    width: 100%;
}

.product-name h5 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 5px;
}


/* ************************** */

.product-btn-block {
    position: absolute;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.7);
    text-align: center;
    padding: 7px;
    height: 80px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-btn-block .selling-price {
    color: #5792ff;
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    padding: 0px 5px;
}

.product-box:hover .product-btn-block {
    background-color: rgb(56, 147, 130, 0.8);
    transition: all 0.3s ease-in-out;
}

.featured-prod-sec .mrp-price {
    color: #c8c8c8;
}

.view-detail {
    color: #fff;
    display: none;
    transition: all 0.3s ease-in-out;
}

.product-box:hover .product-btn-content {
   display: none;
}

.product-box:hover .view-detail {
   display: block;
}


/* ************************** */

.more-btn {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 14px;
}

.about-sec .my-col {
    margin-bottom: 0;
}

.photo-gallery-sec {
    padding: 50px 0;
    text-align: center;
}

.photo-gallery-sec h3 {
    color: #000 !important;
    text-align: center;
}

.photo-gallery-sec h3::before {
    background: rgb(56, 147, 130, 0.8);
    left: calc(50% - 45px);
}


/* contact us */

.contact-sec {
    padding: 50px 0;
}

.contact-sec h3 {
    color: #000;
    text-align: center;
    font-weight: 600 !important;
}

.contact-sec h3::before {
    background: rgb(56, 147, 130, 0.8);
    left: calc(50% - 48px);
}

.contact-sec form {
    width: 60%;
    margin: 0 auto;
}

.sku {
    font-size: 15px;
    text-align: left;
    margin-bottom: -5px;
}

.sku span {
    font-weight: 600;
}



.contact-sec form input {
    padding: 15px 12px;
    font-size: 15px !important;
}

.form-submit {
    padding: 10px 80px;
}

.form-captcha {
    display: flex;
    justify-content: center;
}


/*  */

.about-sec img {
    width: 100%;
}

@media (max-width: 1125px) {
    .about-text {
        padding: 30px 40px;
    }
    .btn-block {
        margin-top: 20px;
    }
}

@media (max-width: 991px) {
    .about-text {
        padding: 70px 55px;
        width: 100%;
        text-align: center;
    }
    .about-col-right img {
        display: none;
    }
    .about-col-right {
        background: url(../../../images/about-us-bg.jpg) top center no-repeat;
        background-size: 100% 100%;
    }
    .about-text {
        position: unset;
    }
    h3::before {
        content: "";
        width: 80px;
        background: #fff;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: calc(50% - 45px);
    }
}


/* 991  */

@media (max-width: 767px) {
    .product-sec {
        padding: 50px 0;
    }
    .about-text {
        padding: 40px 15px;
    }
    .block {
        padding: 40px 0;
    }
    h3 {
        padding-bottom: 18px;
        margin-bottom: 25px !important;
    }
    .about-text h3 {
        margin-bottom: 20px !important;
    }
    .more-btn {
        margin-top: 30px;
        padding: 11px 40px;
    }
    .contact-sec form {
        width: 80%;
    }
}


/* 767  */

@media (max-width: 550px) {
    .contact-sec form {
        width: 100%;
    }
    .contact-sec form input {
        padding: 13px 15px;
    }
}

@media (max-width: 478px) {
    .btn-block a {
        color: #317c6d !important;
        padding: 11px 40px !important;
    }
}